<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-spacer />
    <v-row>
      <v-col class='px-6 pt-5'>
        <v-row>
          <span class='subtitle-2'>{{$t("DOCUMENT_TYPE")}}</span>
        </v-row>
        <v-row>
          <v-select
            v-model='customerSelect'
            :items='customers'
            item-value='id'
            item-text='name'
            :label='$t("CUSTOMERS")'
            @change='getAllLayotus()'
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if='customerSelect'>
      <v-col class='px-6 pt-5'>
        <v-row>
          <span class='subtitle-2'>{{$t("STOCK_TYPE_DESCRIPTION")}}</span>
        </v-row>
        <v-row>
          <v-select
            v-model='documentType'
            :items='layouts'
            item-value='id'
            item-text='layout_name'
            :label='$t("DOCUMENT_TYPE")'
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='6' sm='6'>
        <v-text-field v-model='initialDate' type='date' />
      </v-col>
      <v-col cols='6' sm='6'>
        <v-text-field v-model='endDate' type='date' />
      </v-col>
    </v-row>
    <v-spacer />
    <v-col class='card-footer'>
      <v-btn color='secondary' dark @click='applyFilter'>
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        text: '',
        items: [],
        customers: [],
        layouts: [],
        customerSelect: '',
        documentType: '',
        menu1: false,
        menu2: false,
        month: '',
        date: '',
        initialDate: '',
        endDate: '',
      };
    },
    mounted: function () {
      this.getAllType();
      this.setDates();
    },
    methods: {
      setDates: function () {
        const today = new Date();
        this.initialDate = `${today.getFullYear()}-${today.getMonth() < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.endDate = `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth() < 10 ? `0${lastDayOfMonth.getMonth() + 1}` : lastDayOfMonth.getMonth() + 1}-${lastDayOfMonth.getDate() < 10 ? `0${lastDayOfMonth.getDate()}` : lastDayOfMonth.getDate()}`;
        this.$forceUpdate();
      },
      applyFilter: function () {
        this.text = '';
        if (this.customerSelect && !this.documentType && this.customerSelect !== 'Todos') {
          const customerLayouts = [];
          this.customers.forEach((customer) => {
            if (customer.id === this.customerSelect) {
              customer.layouts.forEach((layout) => {
                let layoutCustomer = "'";
                layoutCustomer = layoutCustomer.concat(layout.layout_name, "'");
                customerLayouts.push(layoutCustomer);
              });
            }
          });

          const customerText = this.text.concat('document_type in (', customerLayouts, ')');
          this.text = customerText;
        } else if (this.customerSelect && this.documentType && this.customerSelect !== 'Todos') {
          this.customers.forEach((customer) => {
            if (customer.id === this.customerSelect) {
              customer.layouts.forEach((layout) => {
                if (layout.id === this.documentType) {
                  if (this.text.length) {
                    this.text += ' and ';
                  }
                  this.text += `document_type = '${layout.layout_name}' and customer_id = ${customer.id}`;
                }
              });
            }
          });
        }
        if (this.initialDate) {
          if (this.text.length) {
            this.text += ' and ';
          }
          this.text += `pl.created_at >= '${this.initialDate}'`;
        }
        if (this.endDate) {
          if (this.text.length) {
            this.text += ' and ';
          }
          this.text += `pl.created_at <= '${this.endDate}'`;
        }
        this.$emit('onFilter', { text: this.text });
        this.$emit('applyFilters');
      },
      getAllType: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customers = result.data.customers;
          this.customers.unshift('Todos');
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getAllLayotus: async function () {
        this.customers.map((customer) => {
          if (customer.id === this.customerSelect) {
            this.layouts = customer.layouts;
          }

          return this.layouts;
        });
      },
    },
  };

</script>
<style>
  .selected-chip {
    border-color: #0488b9 !important;
    color: #FFFFFF !important;
    background-color: #FFFFFF !important;
  }
  .selected-chip span {
    color: #0488b9 !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
